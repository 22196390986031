import React from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import FilterDrawer from "./components/NavBar/FilterDrawer";

function App() {
  return (
    <div className="App">
      <div className="App-body">
        <FilterDrawer />
        <Outlet />
      </div>
    </div>
  );
}

export default App;
