import { gql } from "@apollo/client";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useGeoLocation } from "../geo/LocationProvider";

const GET_CATEGORIES = gql`
  query {
    categories {
      name
    }
  }
`;

export default function FilterDrawer() {
  const [visible, setVisibility] = React.useState(false);
  const { updateLocation } = useGeoLocation();

  const anchor = "right";

  const toggleDrawer = () => {
    setVisibility(!visible);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography
            edge="start"
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Veranstaltungen in Berlin
          </Typography>
          <IconButton color="inherit" size="small" onClick={updateLocation}>
            <MyLocationIcon />
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor={anchor} open={visible} onClose={toggleDrawer}>
        <List>
          <ListItem>
            <DayPicker mode="range" />
          </ListItem>

          {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
