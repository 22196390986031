import { gql, useQuery } from "@apollo/client";
import { Card, CardContent, Link, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { haversineDistanceKm } from "../../utils/geo";
import { useGeoLocation } from "../geo/LocationProvider";
import { SelectedEventContext } from "./DisplayEvents";

const GET_EVENT = gql`
  query Event($id: Int!) {
    event(id: $id) {
      id
      title
      startDate
      description
      sourceUrl
      website
      venuename
      street
      postcode
      city
      longitude
      latitude
      eventSource {
        name
      }
      categories {
        name
      }
      genres {
        name
      }
      price
      priceCurrency
      ticketUrl
    }
  }
`;

function EventDetails() {
  const { id } = useParams();
  return <EventDetailsComponent id={id} />;
}

function EventDetailsComponent({ id }) {
  const { setSelectedEvent } = useContext(SelectedEventContext);
  const { location } = useGeoLocation();
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { id: parseInt(id) },
  });

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      setSelectedEvent(null);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [setSelectedEvent]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  const event = data?.event;

  const dateStr =
    new Date(event.startDate).toLocaleDateString("de-DE", {
      year: "numeric",
      weekday: "long",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }) + " Uhr";

  const venu = event.venuename ? <p>Venue: {event.venuename}</p> : null;

  const categoriesString = event.categories
    .map((category) => category.name)
    .join(", ");
  const categories = categoriesString !== "" ? <p>{categoriesString}</p> : null;

  const genresString = event.genres.map((genre) => genre.name).join(", ");
  const genres = genresString !== "" ? <p>{genresString}</p> : null;

  let address = "";
  if (event.street) {
    address += event.street;
  }
  if (event.postcode) {
    address += ", " + event.postcode;
  }
  if (event.city) {
    address += ", " + event.city;
  }
  const formattedAddress = address
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/\s+/g, "+");
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;

  const website = event.website ? (
    <p>
      Website:{" "}
      <Link href={event.website} target="_blank" rel="noopener noreferrer">
        Website
      </Link>
    </p>
  ) : null;

  const ticket_info = event.price
    ? "- Preis: " + (event.price + " " + event.priceCurrency).replace(".", ",")
    : null;

  const ticketUrl = event.ticketUrl ? (
    <p>
      <Link href={event.ticketUrl} target="_blank" rel="noopener noreferrer">
        Tickets
      </Link>
      {ticket_info}
    </p>
  ) : null;

  const distance =
    location &&
    haversineDistanceKm(
      location.latitude,
      location.longitude,
      event.latitude,
      event.longitude
    ).toFixed(2) + " km";

  return (
    <Card variant="outlined" style={{ height: "100vh" }}>
      <CardContent>
        <Typography variant="h5" align="left">
          {event.title}
        </Typography>
        {/* <Typography variant="subtitle1">{event.startDate}</Typography> */}
        <Typography component="div" variant="body1" align="left">
          {categories}
          {genres}
          <p>Begin: {dateStr}</p>
          {venu}
          <p>Distanz: {distance}</p>
          <p>
            Adresse:{" "}
            <Link
              href={googleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {address}
            </Link>
          </p>
          <p>
            Quelle:{" "}
            <Link
              href={event.sourceUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {event.eventSource.name}
            </Link>
          </p>
          {website}
          {ticketUrl}
          <p>{event.description}</p>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default EventDetails;
export { EventDetailsComponent };
