import React, { createContext, useContext, useEffect, useState } from "react";

// Create a new context
const LocationContext = createContext();

// Create a provider component
export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  const updateLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });
    });
  };

  useEffect(updateLocation, []);

  return (
    <LocationContext.Provider value={{ location, updateLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

// Create a hook to use the LocationContext
export const useGeoLocation = () => {
  return useContext(LocationContext);
};
