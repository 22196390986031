import {
  default as ExpandMore,
  default as ExpandMoreIcon,
} from "@mui/icons-material/ExpandMore";
import { Card, CardContent, CardHeader } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { useMemo, useState } from "react";
import EventList from "./EventList";
// import EventList from "./EventListWindowed";

function formatDate(date) {
  return new Date(date).toLocaleDateString("de-DE", {
    // year: "numeric",
    weekday: "long",
    month: "numeric",
    day: "numeric",
  });
}

export default function GroupEvents({ events }) {
  const groupedEvents = useMemo(
    () =>
      events.reduce((grouped, event) => {
        const date = event.startDate.split("T")[0]; // Assuming startDate is in ISO format
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(event);
        return grouped;
      }, {}),
    [events]
  );

  const sections = Object.keys(groupedEvents).map((date) => {
    const dateStr = formatDate(date);
    return <Group key={dateStr} date={dateStr} events={groupedEvents[date]} />;
  });

  return <>{sections}</>;
}

function Group({ date, events }) {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card key={date}>
      <CardHeader
        title={date}
        titleTypographyProps={{ align: "left" }}
        onClick={handleExpandClick}
        sx={{ paddingLeft: 3, paddingRight: 4 }}
        action={
          <ExpandMore
            // onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ position: "relative", left: "-8px", bottom: "-7px" }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      ></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ height: "100%" }}>
          <EventList events={events} />
        </CardContent>
      </Collapse>
    </Card>
  );
}
