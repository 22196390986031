import { gql, useQuery } from "@apollo/client";
import Fuse from "fuse.js";
import React, { useContext, useMemo, useState } from "react";
import { haversineDistanceKm } from "../../utils/geo";
import GroupEvents from "./GroupEvents";
// import GroupEventsVirtuoso from "./GroupEventsVirtuoso";
// import GroupEventsWindowed from "./GroupEventsWindowed";
import { Card, CardContent } from "@mui/material";
import Chip from "@mui/material/Chip";
import { createContext, useEffect } from "react";
import { useGeoLocation } from "../geo/LocationProvider";
import { EventDetailsComponent } from "./EventDetails";
import SearchBar from "./SearchBar";

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const GET_EVENTS = gql`
  query {
    events {
      idx
      title
      description
      venuename
      startDate
      latitude
      longitude
      categories {
        name
      }
      genres {
        name
      }
    }
  }
`;

export const SelectedEventContext = createContext();

export const SelectedEventProvider = ({ children }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <SelectedEventContext.Provider value={{ selectedEvent, setSelectedEvent }}>
      {children}
    </SelectedEventContext.Provider>
  );
};

export const DistanceContext = createContext(new Map());

export function DistanceProvider({ events, children }) {
  const { location } = useGeoLocation();
  const distanceByEventId = useMemo(() => {
    if (!location || !events) {
      return new Map();
    }
    return events.reduce((acc, event) => {
      acc[event.idx] = haversineDistanceKm(
        location.latitude,
        location.longitude,
        event.latitude,
        event.longitude
      );
      return acc;
    }, {});
  }, [events, location]);

  return (
    <DistanceContext.Provider value={distanceByEventId}>
      {children}
    </DistanceContext.Provider>
  );
}

export default function DisplayEvents() {
  const { loading, error, data } = useQuery(GET_EVENTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  let events = data?.events || [];

  return (
    <SelectedEventProvider>
      <DistanceProvider events={events}>
        <Events events={events} />
      </DistanceProvider>
    </SelectedEventProvider>
  );
}

function Events({ events }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const { selectedEvent, setSelectedEvent } = useContext(SelectedEventContext);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      setSelectedEvent(null);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [setSelectedEvent]);

  const fuse = useMemo(
    () =>
      new Fuse(events, {
        threshold: 0.3,
        keys: [
          "title",
          "description",
          "venuename",
          "categories.name",
          "genres.name",
        ],
      }),
    [events]
  );

  const categoryCounts = useMemo(
    () =>
      events.reduce((counts, event) => {
        event.categories.forEach((category) => {
          if (counts[category.name]) {
            counts[category.name]++;
          } else {
            counts[category.name] = 1;
          }
        });
        return counts;
      }, {}),
    [events]
  );

  const eventsFiltered = useMemo(() => {
    let filteredEvents = events;
    if (searchQuery && searchQuery.length > 0) {
      filteredEvents = fuse.search(searchQuery).map(({ item }) => item);
    }
    if (selectedCategories.size > 0) {
      filteredEvents = filteredEvents.filter(
        (event) =>
          !event.categories.some((cat) => selectedCategories.has(cat.name))
      );
    }
    return filteredEvents;
  }, [events, searchQuery, selectedCategories, fuse]);

  if (selectedEvent) {
    return <EventDetailsComponent id={selectedEvent} />;
  }

  return (
    <>
      <SearchBar setSearchQuery={debounce(setSearchQuery, 400)} />
      <CategorySelection
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        categoryCounts={categoryCounts}
      />
      <GroupEvents events={eventsFiltered} />
    </>
  );
}

function CategorySelection({
  selectedCategories,
  setSelectedCategories,
  categoryCounts,
}) {
  function handleClick(e) {
    const words = e.target.innerText.split(" ");
    const category = words.slice(0, -1).join(" ");
    const newSelectedCategories = new Set(selectedCategories);
    if (newSelectedCategories.has(category)) {
      newSelectedCategories.delete(category);
    } else {
      newSelectedCategories.add(category);
    }
    setSelectedCategories(newSelectedCategories);
  }

  return (
    <Card>
      <CardContent>
        {Object.entries(categoryCounts)
          .sort((a, b) => b[1] - a[1])
          .map(([category, count]) => (
            <Chip
              label={category + " " + count}
              onClick={handleClick}
              variant={selectedCategories.has(category) ? "" : "outlined"}
              style={{ margin: "2px" }}
              // disableRipple
            />
          ))}
      </CardContent>
    </Card>
  );
}
