import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext } from "react";
import { DistanceContext, SelectedEventContext } from "./DisplayEvents";

function sortEvents(events, distanceByEventId) {
  events.sort((a, b) => {
    const dist_a = distanceByEventId[a.idx];
    const dist_b = distanceByEventId[b.idx];
    if (dist_a == null && dist_b == null) {
      return 0;
    }
    if (dist_a == null) {
      return 1;
    }
    if (dist_b == null) {
      return -1;
    }
    if (dist_a < dist_b) {
      return -1;
    }
    if (dist_a > dist_b) {
      return 1;
    }
    if (a.startDate < b.startDate) {
      return -1;
    }
    if (a.startDate > b.startDate) {
      return 1;
    }
    return 0;
  });
}

export default function EventList({ events }) {
  const distanceByEventId = useContext(DistanceContext);
  sortEvents(events, distanceByEventId);
  return (
    <List component="nav">
      {events.map((event, index, self) => {
        return (
          <ListEntry
            key={index}
            showDivider={index !== self.length - 1}
            event={event}
          />
        );
      })}
    </List>
  );
}

function ListEntry({ showDivider, event }) {
  const distanceByEventId = useContext(DistanceContext);
  const { setSelectedEvent } = useContext(SelectedEventContext);
  let { idx, title, venuename, startDate, categories, genres } = event;
  const distance = distanceByEventId[idx];
  const time = startDate.slice(11, 16);
  let timeString = time + " Uhr";
  if (time === "00:00") {
    timeString = "";
  }
  const categoriesString = categories.map((c) => c.name).join(", ");
  const genresString = genres.map((g) => g.name).join(", ");
  const formattedDistance =
    distance != null ? parseFloat(distance).toFixed(1) + " km " : distance;
  const secondary = [
    formattedDistance,
    timeString,
    categoriesString,
    genresString,
    venuename,
  ]
    .filter((e) => e != null && e !== "")
    .join(" | ");
  return (
    <>
      <ListItem
        alignItems="flex-start"
        sx={{ paddingLeft: 1, paddingRight: 1 }}
      >
        <ListItemButton
          onClick={() => setSelectedEvent(idx)}
          // component={RouterLink}
          // to={`/events/${idx}`}
          underline="none"
          color="inherit"
          rel="noopener noreferrer"
          sx={{ padding: 0 }}
        >
          <ListItemText primary={title} secondary={<>{secondary}</>} />
        </ListItemButton>
        {/* {showDetails && <EventDetails event={event} />} */}
      </ListItem>
      {showDivider && <Divider component="li" />}
    </>
  );
}
